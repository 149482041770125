import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import {
  ClientSolutionAndSummary,
  ImageBlock,
  Introduction,
  MultipleImageBlock,
  TextBlock,
  Anchor,
} from '../../../components/molecules/Cases'
import {
  Mouse,
  LightBulb,
  PenAndPaper,
  Community,
  Click,
  VideoPlayer,
} from '../../../components/molecules/Icons'

import SectionContainer from '../../../components/molecules/SectionContainer'
import useStaticImage from '../../../hooks/useStaticImage'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import LocTokContent from './content.json'

import './styles.scss'

const { clientSolutionAndSummary } = LocTokContent

const Content = () => {
  const [getImage] = useStaticImage()

  const Intro = () => {
    return (
      <>
        <Introduction Title={() => <b>Dalgas Aves Brasil</b>}>
          Saiba como a marca migrou a sua estratégia de “boca a boca” para o ambiente
          digital
        </Introduction>

        <ClientSolutionAndSummary
          client={clientSolutionAndSummary.client}
          solutions={clientSolutionAndSummary.solutions}
          brand={clientSolutionAndSummary.brand}
          summary={clientSolutionAndSummary.summary}
        />

        <SectionContainer className="dalgas__introduction-images">
          <div>
            <GatsbyImage
              image={getImage('dalgas-ave-1.webp')}
              alt="Arara Canindé, 2015 | Obra Christian Dalgas"
            />
            <span>Arara Canindé, 2015 | Obra Christian Dalgas</span>
          </div>
          <div>
            <GatsbyImage
              image={getImage('dalgas-indigena-1.webp')}
              alt="Série Índios II | Obra Christian Dalgas"
            />
            <span>Série Índios II | Obra Christian Dalgas</span>
          </div>
        </SectionContainer>
      </>
    )
  }

  const GetToKnowUs = () => {
    return (
      <div id="conheca">
        <TextBlock hasMargin title="Conheça a Dalgas Aves Brasil">
          A marca surgiu a partir da paixão de Christian Dalgas Frisch pela fotografia.
          Ele vem de uma família de <b>amantes da natureza</b> e transformou o seu rico
          portfólio de cliques em locais pouco explorados do Brasil em quadros exclusivos.
          Além disso, junto com o seu pai,
          <b>
            {' '}
            realizou a publicação dos livros “Jardim dos Beija-Flores” e “Aves Brasileiras
            e Plantas que as Atraem”
          </b>{' '}
          e a criação de relógios de parede inspirados na fauna que tocam o som de um
          pássaro diferente a cada hora.
          <br />
          <br />
          Recentemente, Christian e sua esposa, Sarah, produziram uma{' '}
          <b>
            coleção de porcelanas com imagens de pássaros brasileiros fotografados por
            ele.{' '}
          </b>
          Assim, nasceu a Dalgas Aves Brasil.
          <br />
          <br />
          Em 2021, iniciaram também a produção e venda de gravuras com as ilustrações de
          Svend Frisch, avô de Christian, no qual desenhou e catalogou 1.800 aves
          brasileiras e que se tornaram parte da história do nosso país. Produzidas a
          partir de 1940, esse trabalho foi incentivado pelo antigo presidente Getúlio
          Vargas.
          <br />
          <br />
          Os produtos são valorizados por grandes influenciadores da alta sociedade
          brasileira, como Lala Rudge, Shantal e Bruno Gagliasso.{' '}
          <b>
            Só faltava uma ação para completar a jornada dessa empresa: a visibilidade nas
            plataformas digitais.
          </b>{' '}
          E, por indicação de outro cliente nosso, a Dalgas Aves Brasil se tornou cliente
          da DR_.
        </TextBlock>
        <ImageBlock
          image="dalgas-pessoas.webp"
          alt="Christian e Sarah Dalgas na Exposição Expedições Christian Dalgas, 2021 | Fotografia: Denise Andrade"
        />
      </div>
    )
  }

  const InitialScenario = () => {
    return (
      <div id="cenario">
        <TextBlock
          title="Qual era o cenário inicial do cliente? E os objetivos?"
          hasMargin
        >
          A primeira estratégia de vendas da marca foi o “boca a boca” para conhecidos e
          contatos, gerando visibilidade. Esse modelo possui uma limitação com relação ao
          alcance de novos públicos e, por isso, a empresa criou uma página no Instagram e
          um site para alavancar as vendas. Por conta da falta de tempo para fazer a
          gestão do conteúdo, não surgiram muitos resultados iniciais.
          <br />
          <br />
          Então, em janeiro de 2021,{' '}
          <b>
            a marca contratou a DR_ a fim de construir uma estratégia para o canal,
            ampliando o negócio e gerando maior visibilidade, conversões e exposição da
            marca e dos feitos do Christian e de sua família.
          </b>
        </TextBlock>

        <MultipleImageBlock
          images={['dalgas-ave-2.webp', 'dalgas-indigena-2.webp', 'dalgas-ave-3.webp']}
          alt="Reprodução Instagram"
        />
      </div>
    )
  }

  const Talents = () => {
    return (
      <SectionContainer className="dalgas__talents">
        <div>
          <h3 id="talentos" className="dalgas__subtitle">
            Quais são os talentos da DR_ que atuam no projeto?
          </h3>

          <div className="dalgas__talents__icons">
            <div>
              <div>
                <LightBulb />
              </div>
              <span>Creative Lead</span>
            </div>
            <div>
              <div>
                <Mouse />
              </div>
              <span>Designer</span>
            </div>
            <div>
              <div>
                <PenAndPaper />
              </div>
              <span>Marketing Assistant</span>
            </div>
          </div>
        </div>

        <GatsbyImage
          image={getImage('dalgas-phones.webp')}
          alt="Instagram - Cliente Dalgas"
        />
      </SectionContainer>
    )
  }

  const ProjectSteps = () => {
    const [activeTab, setActiveTab] = useState(-1)
    const [width] = useWindowDimensions()
    const accordionData = [
      {
        title: 'Reunião de alinhamento',
        text: 'Para obter informações para o briefing e entender o objetivo do cliente no ambiente digital;',
      },
      {
        title: 'Elaboração do plano de mídia',
        text: 'Foram identificadas a persona, linha editorial e identidade visual para as redes sociais;',
      },
      {
        title: 'Aprovação final',
        text: 'Ajustes e validação do cliente;',
      },
      {
        title: 'Recebimento de conteúdos do cliente',
        text: 'Fotos, informações e livros da família Dalgas;',
      },
      {
        title: 'Desenvolvimento do calendário editorial',
        text: 'Criação, aprovação e início do trabalho no Instagram.',
      },
    ]

    function handleTab(index) {
      if (width > 769) return

      setActiveTab((previousTab) => {
        return previousTab === index ? -1 : index
      })
    }

    return (
      <>
        <SectionContainer>
          <h3 id="etapas" className="dalgas__subtitle">
            Quais foram as etapas do projeto?
          </h3>

          <div className="dalgas__accordion">
            {accordionData.map(({ title, text }, index) => (
              <div
                className={`dalgas__accordion__card ${
                  index === activeTab ? 'dalgas__accordion__card--active' : ''
                }`}
              >
                <span onClick={() => handleTab(index)}>
                  <b>{String(index + 1).padStart(2, '0')}.</b> {title}
                </span>

                <p>{text}</p>
              </div>
            ))}
          </div>
        </SectionContainer>
        <MultipleImageBlock
          images={['dalgas-ave-4.webp', 'dalgas-candle.webp', 'dalgas-pratos-1.webp']}
          alt="Reprodução Instagram"
        />
      </>
    )
  }

  const Results = () => {
    return (
      <>
        <SectionContainer className="dalgas__results">
          <h3 id="resultados" className="dalgas__subtitle">
            Quais foram os resultados obtidos até aqui?
          </h3>

          <div className="dalgas__results__icons">
            <div>
              <Community />
              <span>
                Aumento do número de seguidores: de 4k, em 2021, para 13k em dezembro/22
              </span>
            </div>
            <div>
              <Click />
              <span>
                Crescimento da taxa de cliques do link da bio (onde temos os principais
                link de vendas: site, catálogo e WhatsApp) para uma média de 80%
              </span>
            </div>
            <div>
              <VideoPlayer />
              <span>
                Identificação do reel como o formato que mais gerou resultados na rede
                social
              </span>
            </div>
          </div>

          <Anchor id="projeto" href="https://www.instagram.com/dalgasavesbrasil/">
            Clique aqui para conhecer nosso trabalho no Instagram da marca
          </Anchor>
        </SectionContainer>
      </>
    )
  }

  return (
    <>
      <Intro />
      <GetToKnowUs />
      <InitialScenario />
      <Talents />
      <ProjectSteps />
      <Results />
    </>
  )
}

export default Content
